<template>
    <v-card>
        <v-card-text>
            <div id="chart">
                <apexchart type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

import { EventBus } from '@/main.js'
import VueApexCharts from 'vue-apexcharts'
import Autocomplete from 'vue2-google-maps/dist/components/autocomplete.vue';

export default {
    name: 'bar-chart-transaction-type',
    components: {
        apexchart: VueApexCharts,
    },
        
        data: () => ({
          
          series: [{
            data: [0,0,0]
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 380
            },
            plotOptions: {
              bar: {
                barHeight: '60%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'bottom'
                },
              }
            },
            colors: ['#BD2E2E', '#5AB55E', '#626262'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff']
              },
              formatter: function (val, opt) {
                //window.console.log(val)
                return opt.w.globals.labels[opt.dataPointIndex] + ": " + val + "€"
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: ['Card', 'Cash', 'Total amount'
              ],
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            title: {
                text: 'Payment method',
                align: 'center',
                floating: true,
                style: {
                  fontSize: "16px",
                  fontWeight: '500'
                }
            },
            subtitle: {
                text: '',
                align: 'center',
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function () {
                    return '€'
                  }
                }
              }
            }
          },
          
          
        }),

        created() {
          EventBus.$on('bar-chart-transaction-type', (data) => {
            //window.console.log("bar-chart-transaction-type")
            //window.console.log(data);

            this.series = [{
              data: [data.credit_card_payments_amount, data.cash_payments_amount,data.total_amount]
            }]
          })
        },

        beforeDestroy() {
          EventBus.$off('bar-chart-transaction-type'); 
        }
}

</script>